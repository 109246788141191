// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type ApiMethod = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS" | "FUTURE_VALUE";
export type ApiMethodKnownValue = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS";
export const ApiMethodKnownValues: ApiMethodKnownValue[] = [ "GET","PUT","POST","PATCH","DELETE","HEAD","OPTIONS" ];



export type ApiLogsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; path?: Maybe<string>; responseCode?: Maybe<Int>; method?: Maybe<ApiMethod> }
export type CreateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateOrganizationInput = { apiVersion: string }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {};
export type PossibleTypes = {

};

export type EntityName = 
  | "Organization"
  | "SubOrganization"
  | "ServerKey"
  | "ApiLog"
export type EntityFieldMap = { Organization: "uuid" | "subOrganizationCreationEnabled" | "subOrganizations" | "alertEmails" | "pinnedVersion"; SubOrganization: "uuid" | "displayName" | "createdAt"; ServerKey: "uuid" | "publicId" | "name" | "description" | "createdAt"; ApiLog: "uuid" | "createdAt" | "path" | "method" | "responseCode" | "errorCode" | "errorMessage" | "ipAddress" | "apiKey" | "apiVersion" }
export type QueryVariablesMap = { apiLog: "uuid"; apiLogs: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "path" | "responseCode" | "method" }

export type QueryName = 
  | "GetApiLogs"
  | "GetApiLogEndpoints"
  | "GetApiLogCodes"
  | "AllServerKeys"
  | "GetAPIVersions"
  | "GetOrganizationAPIVersion"

const ApiLogFragmentDocument = gql`
fragment ApiLog on ApiLog {
  uuid
  createdAt
  ipAddress
  path
  responseCode
  errorCode
  errorMessage
  apiKey
  apiVersion
  method
}

`
export const ApiLogFragmentProps: Fragment<ApiLogFragment> = {
  document: ApiLogFragmentDocument,
  fragmentName: "ApiLog",
  entityName: "ApiLog",
  __do_not_use_Data: null
}
export type ApiLogFragment = {
  __typename: "ApiLog";
  uuid: UUID;
  createdAt: ISOString;
  ipAddress: string;
  path: string;
  responseCode: Int;
  errorCode: Maybe<Int>;
  errorMessage: Maybe<string>;
  apiKey: string;
  apiVersion: Maybe<string>;
  method: ApiMethod
}

const ServerKeyFragmentDocument = gql`
fragment ServerKey on ServerKey {
  uuid
  publicId
  name
  description
  createdAt
}

`
export const ServerKeyFragmentProps: Fragment<ServerKeyFragment> = {
  document: ServerKeyFragmentDocument,
  fragmentName: "ServerKey",
  entityName: "ServerKey",
  __do_not_use_Data: null
}
export type ServerKeyFragment = {
  __typename: "ServerKey";
  uuid: UUID;
  publicId: string;
  name: Maybe<string>;
  description: Maybe<string>;
  createdAt: ISOString
}

export type GetApiLogsData = { __typename: "ApiLogsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<ApiLogFragment> }
export type GetApiLogsVariables = { filters?: Maybe<ApiLogsFilter>; cursor?: Maybe<string> }
export const GetApiLogs: Query<GetApiLogsData, GetApiLogsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogs($filters: ApiLogsFilter, $cursor: String) {
  apiLogs(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...ApiLog
    }
  }
}
${ApiLogFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogEndpointsData = { __typename: "ApiLogEndpointsOutput"; paths: Array<string> }
export type GetApiLogEndpointsVariables = { [key: string]: never }
export const GetApiLogEndpoints: Query<GetApiLogEndpointsData, GetApiLogEndpointsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogEndpoints {
  apiLogEndpoints {
    paths
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogCodesData = { __typename: "ApiLogCodesOutput"; codes: Array<Int> }
export type GetApiLogCodesVariables = { [key: string]: never }
export const GetApiLogCodes: Query<GetApiLogCodesData, GetApiLogCodesVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogCodes {
  apiLogCodes {
    codes
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type AllServerKeysData = Array<ServerKeyFragment>
export type AllServerKeysVariables = { [key: string]: never }
export const AllServerKeys: Query<AllServerKeysData, AllServerKeysVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query AllServerKeys {
  serverKeys {
    ...ServerKey
  }
}
${ServerKeyFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteServerKeyData = MutationStatus
export type DeleteServerKeyVariables = { uuid: UUID }
export const DeleteServerKey: Mutation<DeleteServerKeyData, DeleteServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteServerKey($uuid: UUID!) {
  deleteServerKey(uuid: $uuid)
}

`,
  endpointName: "deleteServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateServerKeyData = { __typename: "CreateServerKeyOutput"; serverKey: ServerKeyFragment; bearerToken: string }
export type CreateServerKeyVariables = { serverKeyInput: CreateServerKeyInput }
export const CreateServerKey: Mutation<CreateServerKeyData, CreateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateServerKey($serverKeyInput: CreateServerKeyInput!) {
  createServerKey(serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
    bearerToken
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "createServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateServerKeyData = { __typename: "UpdateServerKeyOutput"; serverKey: ServerKeyFragment }
export type UpdateServerKeyVariables = { uuid: UUID; serverKeyInput: UpdateServerKeyInput }
export const UpdateServerKey: Mutation<UpdateServerKeyData, UpdateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateServerKey($uuid: UUID!, $serverKeyInput: UpdateServerKeyInput!) {
  updateServerKey(uuid: $uuid, serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "updateServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetAPIVersionsData = { __typename: "APIVersionsOutput"; versions: Array<string> }
export type GetAPIVersionsVariables = { [key: string]: never }
export const GetAPIVersions: Query<GetAPIVersionsData, GetAPIVersionsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetAPIVersions {
  apiVersions {
    versions
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetOrganizationAPIVersionData = { __typename: "Organization"; uuid: UUID; pinnedVersion: string }
export type GetOrganizationAPIVersionVariables = { [key: string]: never }
export const GetOrganizationAPIVersion: Query<GetOrganizationAPIVersionData, GetOrganizationAPIVersionVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetOrganizationAPIVersion {
  myOrganization {
    uuid
    pinnedVersion
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type UpdateOrganizationAPIVersionData = { __typename: "UpdateOrganizationOutput"; organization: { __typename: "Organization"; uuid: UUID; pinnedVersion: string } }
export type UpdateOrganizationAPIVersionVariables = { input: UpdateOrganizationInput }
export const UpdateOrganizationAPIVersion: Mutation<UpdateOrganizationAPIVersionData, UpdateOrganizationAPIVersionVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateOrganizationAPIVersion($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    organization {
      uuid
      pinnedVersion
    }
  }
}

`,
  endpointName: "updateOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}