import { useEffect, useState } from "react";
import classNames from "classnames";

import { OrganizationLogo } from "components/OrganizationLogo/OrganizationLogo";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { TargetFrontend } from "generated/unauth-account";
import { TFunction, useTranslation } from "i18n";

import { IdentitiesQueryData } from "./IdentitiesQuery";
import {
  Identity,
  isPickableIdentity,
  isPickableStatus,
  usePickIdentity,
} from "./utils";

/**
 * Lets users connected with an account token switch between all the identities
 * associated to their account. Shouldn't be used when there is no logged-in
 * user, or if the user isn't logged in with an account token.
 */
export const IdentityPicker = ({
  identities,
  onAfterPick,
  targetFrontend,
}: {
  identities: IdentitiesQueryData["identities"];
  onAfterPick?: () => void;
  targetFrontend?: TargetFrontend | null;
}) => {
  const t = useTranslation();
  const pickIdentity = usePickIdentity();
  const [automaticallyPickIdentityUuid, setAutomaticallyPickIdentityUuid] =
    useState<UUID | undefined>();

  useEffect(() => {
    if (!automaticallyPickIdentityUuid) return;

    const matchingIdentity = identities
      .flatMap((it) => it.identitiesForOrganization)
      .find((it) => it.uuid === automaticallyPickIdentityUuid);
    if (!matchingIdentity) return;

    setAutomaticallyPickIdentityUuid(undefined);
    if (isPickableIdentity(matchingIdentity)) {
      pickIdentity(matchingIdentity, {
        canGoBack: true,
      });
    }
  }, [identities, automaticallyPickIdentityUuid, pickIdentity, targetFrontend]);

  return (
    <div className="flex-col w-full divide-y">
      {identities.map(({ organization, identitiesForOrganization }) => (
        <div
          className="flex-col gap-12 py-16 first:pt-0 last:pb-0 select-none"
          key={organization.uuid}
        >
          <h2 className="leading-normal">{organization.displayName}</h2>
          <div className="flex-col sm:gap-10 gap-12">
            {identitiesForOrganization.map((identity) => (
              <TooltipWrapper
                key={identity.uuid}
                show={!isPickableIdentity(identity)}
                position="right"
                label={displayStatus(t, identity)}
              >
                <div
                  className={classNames("flex gap-16 items-center", {
                    "cursor-pointer": isPickableIdentity(identity),
                    "opacity-50": !isPickableIdentity(identity),
                  })}
                  onClick={() => {
                    if (!isPickableIdentity(identity)) return;
                    pickIdentity(identity, { canGoBack: true });
                    onAfterPick?.();
                  }}
                >
                  <OrganizationLogo fixedIcon="people" />
                  <div className="flex-col gap-2">
                    <h3 className="truncate text-primary-dark text-16 font-medium">
                      {identity.subOrganization.displayName ??
                        identity.subOrganization.organization.displayName}
                    </h3>
                    {identity.subOrganization.customerEnvironment === "TEST" ? (
                      <div className=" border rounded bg-orange-100 text-orange border-orange text-xs pl-8 pr-8 self-start ">
                        Test
                      </div>
                    ) : (
                      <div className="border rounded bg-light-blue text-primary border-primary text-xs pl-8 pr-8 self-start">
                        Production
                      </div>
                    )}
                  </div>
                </div>
              </TooltipWrapper>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const displayStatus = (t: TFunction, identity: Identity) => {
  const status = identity.status;
  if (isPickableStatus(status)) return "";
  switch (status) {
    case "UNSUPPORTED_PASSWORD_LOGIN":
      return t("identity_picker.unavailable.unsupported_password_login");
    case "UNSUPPORTED_GOOGLE_LOGIN":
      return t("identity_picker.unavailable.unsupported_google_login");
    case "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN":
      return t("identity_picker.unavailable.unsupported_one_time_token_login");
    case "DEACTIVATED":
      return t("identity_picker.unavailable.deactivated", {
        organizationName: "Nabla",
      });
  }
};
