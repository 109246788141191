import gql from "graphql-tag";
import * as Yup from "yup";

import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { FormInput } from "components/Form/Input/FormInput";
import { Label } from "components/Form/Label/Label";
import { FormModal } from "components/Modal/FormModal";
import {
  CreateWebhook,
  GetWebhooks,
  UpdateWebhook,
  WebhookEventType,
  WebhookEventTypeKnownValues,
  WebhookFragment,
} from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useField } from "hooks/useField";
import { TFunction, useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { webhookEventTypeString } from "../WebhookEvents/types";

gql`
  # schema = COPILOT_API_DEVELOPER
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook {
        ...Webhook
      }
    }
  }

  mutation UpdateWebhook($uuid: UUID!, $input: UpdateWebhookInput!) {
    updateWebhook(uuid: $uuid, input: $input) {
      webhook {
        ...Webhook
      }
    }
  }
`;

type FormValues = {
  url: string;
  enabledTypes: WebhookEventType[];
};

export const WebhookComposer = ({
  webhook,
  onClose,
}: {
  webhook?: WebhookFragment;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [createWebhook] = useMutation(CreateWebhook, {
    onSuccess: (output, client) => {
      client.update({
        query: GetWebhooks,
        write: (data) => {
          data.unshift(output.webhook);
        },
      });
      notifier.success(
        t("developers.webhooks.webhook_composer.webhook_created"),
      );
      onClose();
    },
  });
  const [updateWebhook] = useMutation(UpdateWebhook, {
    onSuccess: () => {
      notifier.success(
        t("developers.webhooks.webhook_composer.webhook_updated"),
      );
      onClose();
    },
  });
  return (
    <FormModal<FormValues>
      title={
        webhook
          ? t("developers.webhooks.webhook_composer.edit_the_webhook")
          : t("developers.webhooks.webhook_composer.add_a_new_webhook")
      }
      submitLabel={
        webhook
          ? t("developers.webhooks.webhook_composer.save")
          : t("developers.webhooks.webhook_composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        url: webhook?.url ?? "",
        enabledTypes: webhook?.enabledTypes ?? [],
      }}
      validationSchema={{
        url: Yup.string().trim().required().url(),
        enabledTypes: Yup.array().min(
          1,
          t("developers.webhooks.types.select_at_least_one_event"),
        ),
      }}
      onSubmit={({ url, enabledTypes }) =>
        webhook
          ? updateWebhook({
              uuid: webhook.uuid,
              input: { url, enabledTypes },
            })
          : createWebhook({
              input: { url, enabledTypes },
            })
      }
    >
      <FormInput
        name="url"
        label={t("developers.webhooks.webhook_composer.url")}
        placeholder="https://example.com/webhook"
        wrapperClassName="flex-fill"
      />
      <EventTypeCheckboxes />
    </FormModal>
  );
};

const EventTypeCheckboxes = () => {
  const [{ value: enabledTypes }, { error }, { setValue: setEnabledTypes }] =
    useField<WebhookEventType[]>({ name: "enabledTypes" });
  const t = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      <Label
        name="enabledTypes"
        label={t("developers.webhooks.webhook_composer.enabled_types")}
        error={error}
      />
      {WebhookEventTypeKnownValues.map((checkboxType) => (
        <div key={checkboxType} className="flex flex-col">
          <CheckBox
            className="m-0 p-0"
            name={`enabledTypes_${checkboxType}`}
            label={
              <samp className="bg-grey-100 border border-grey-200 py-2 px-4 rounded-4 inline-block overflow-hidden text-12">
                {webhookEventTypeString(checkboxType)}
              </samp>
            }
            checked={enabledTypes.includes(checkboxType)}
            onChange={(checked) => {
              if (checked) {
                setEnabledTypes([...enabledTypes, checkboxType]);
              } else {
                setEnabledTypes(
                  enabledTypes.filter((type) => type !== checkboxType),
                );
              }
            }}
          />
          <div className="text-grey-300 text-14 ml-32 my-2">
            {webhookEventTypeName(t, checkboxType)}
          </div>
        </div>
      ))}
    </div>
  );
};

const webhookEventTypeName = (t: TFunction, value: WebhookEventType) => {
  switch (value) {
    case "GENERATE_NOTE_ASYNC_SUCCEEDED":
      return t(
        "developers.webhooks.types.generate_note_async_succeeded.description",
      );
    case "GENERATE_NOTE_ASYNC_FAILED":
      return t(
        "developers.webhooks.types.generate_note_async_failed.description",
      );
    case "TRANSCRIBE_ASYNC_SUCCEEDED":
      return t(
        "developers.webhooks.types.transcribe_async_succeeded.description",
      );
    case "TRANSCRIBE_ASYNC_FAILED":
      return t("developers.webhooks.types.transcribe_async_failed.description");
    case "DICTATE_ASYNC_SUCCEEDED":
      return t("developers.webhooks.types.dictate_async_succeeded.description");
    case "DICTATE_ASYNC_FAILED":
      return t("developers.webhooks.types.dictate_async_failed.description");
  }
};
