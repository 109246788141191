import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import {
  GetWebhookEvent,
  RetryWebhookCall,
  WebhookCallFragment,
} from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = COPILOT_API_DEVELOPER
  query GetWebhookEvent($uuid: UUID!) {
    webhookEvent(uuid: $uuid) {
      webhookEvent {
        ...WebhookEvent
        calls {
          ...WebhookCall
        }
      }
    }
  }

  fragment WebhookCall on WebhookCall {
    uuid
    createdAt
    status
    lastAttemptedAt
    numFailures
    nextAttemptAt

    webhook {
      ...Webhook
    }

    failures {
      uuid
      exceptionText
      httpStatusCode
      httpResponse
      createdAt
    }
  }

  mutation RetryWebhookCall($uuid: UUID!) {
    retryWebhookCall(webhookCallUuid: $uuid) {
      webhookCall {
        ...WebhookCall
      }
    }
  }
`;

export const WebhookEvent = ({ uuid }: { uuid: UUID }) => {
  const { data, loading } = useQuery(GetWebhookEvent, { variables: { uuid } });

  if (loading) return <Spinner />;

  return (
    <>
      {data?.webhookEvent.calls.map((call) => (
        <div key={call.uuid} className="p-8 space-y-16">
          <Call call={call} />
        </div>
      ))}
    </>
  );
};

const Call = ({ call }: { call: WebhookCallFragment }) => {
  const t = useTranslation();
  const [retryWebhookCall] = useMutation(RetryWebhookCall, {
    onSuccess: () =>
      notifier.success(t("developers.webhook_events.webhook_event_resent")),
  });

  return (
    <div className="text-grey text-12 px-20">
      <div className="flex">
        <div className="flex-shrink">
          <div>
            <span className="font-semibold">
              {t("developers.webhook_events.webhook_event.webhook")}
            </span>
            {call.webhook.url}
          </div>
          <div>
            {call.nextAttemptAt ? (
              <div>
                <span className="font-semibold">
                  {t("developers.webhook_events.webhook_event.next_attempt")}
                </span>
                {call.nextAttemptAt.format({ relative: "timeOrDate" })}
              </div>
            ) : undefined}
          </div>
          <div>
            {call.failures.isEmpty() ? undefined : (
              <>
                <div className="font-semibold">
                  {t("developers.webhook_events.webhook_event.failures")}
                </div>
                <div className="space-y-8">
                  {call.failures.map((failure, i) => (
                    <div key={failure.uuid}>
                      <Failure index={i} failure={failure} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="px-20">
          {call.status === "FAILED" ? (
            <Button
              onClick={() => retryWebhookCall({ uuid: call.uuid })}
              label="Retry"
              secondary
            />
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

const Failure = ({
  index,
  failure,
}: {
  index: number;
  failure: {
    uuid: UUID;
    exceptionText: Maybe<string>;
    httpStatusCode: Maybe<number>;
    httpResponse: Maybe<string>;
    createdAt: ISOString;
  };
}) => {
  const t = useTranslation();
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <div
      className={classNames("p-8 rounded border shadow-sm-outlined", {
        "hover:bg-grey-100": isCollapsed,
      })}
    >
      <div
        className="flex justify-between hover:cursor-pointer"
        onClick={() => setCollapsed(!isCollapsed)}
      >
        <h4>
          Attempt #{index}{" "}
          {failure.createdAt.format({
            relative: "timeOrDate",
          })}
        </h4>
        <Icon name="chevron" rotate={isCollapsed ? 0 : 90} />
      </div>
      <div
        className={classNames(
          "transition overflow-hidden duration-300",
          { "max-h-0": isCollapsed },
          isCollapsed ? "opacity-0" : "opacity-100",
        )}
      >
        {failure.exceptionText ? (
          failure.exceptionText
        ) : (
          <div className="flex-col">
            <div>
              <span className="font-semibold">
                {t("developers.webhook_events.webhook_event.status_code")}
              </span>
              {failure.httpStatusCode}
            </div>
            <div className="font-semibold">
              {t("developers.webhook_events.webhook_event.response")}
            </div>

            <div className="rounded shadow-sm-outlined border bg-grey-100 p-8 overflow-y-auto">
              <pre className="whitespace-pre-wrap text-11">
                {failure.httpResponse}
              </pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
