// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type Permission = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES" | "FUTURE_VALUE";
export type PermissionKnownValue = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES";
export const PermissionKnownValues: PermissionKnownValue[] = [ "USE_WEB_APP","VIEW_COPILOT_USERS","VIEW_API_KEYS","SEND_HELP_MESSAGES" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH" ];
export type NablaProduct = "ASSISTANT" | "CORE_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "ASSISTANT" | "CORE_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "ASSISTANT","CORE_API" ];
export type WebhookEventType = "GENERATE_NOTE_ASYNC_SUCCEEDED" | "GENERATE_NOTE_ASYNC_FAILED" | "TRANSCRIBE_ASYNC_SUCCEEDED" | "TRANSCRIBE_ASYNC_FAILED" | "DICTATE_ASYNC_SUCCEEDED" | "DICTATE_ASYNC_FAILED" | "FUTURE_VALUE";
export type WebhookEventTypeKnownValue = "GENERATE_NOTE_ASYNC_SUCCEEDED" | "GENERATE_NOTE_ASYNC_FAILED" | "TRANSCRIBE_ASYNC_SUCCEEDED" | "TRANSCRIBE_ASYNC_FAILED" | "DICTATE_ASYNC_SUCCEEDED" | "DICTATE_ASYNC_FAILED";
export const WebhookEventTypeKnownValues: WebhookEventTypeKnownValue[] = [ "GENERATE_NOTE_ASYNC_SUCCEEDED","GENERATE_NOTE_ASYNC_FAILED","TRANSCRIBE_ASYNC_SUCCEEDED","TRANSCRIBE_ASYNC_FAILED","DICTATE_ASYNC_SUCCEEDED","DICTATE_ASYNC_FAILED" ];
export type WebhookCallStatus = "ATTEMPTING" | "SUCCEEDED" | "FAILED" | "FUTURE_VALUE";
export type WebhookCallStatusKnownValue = "ATTEMPTING" | "SUCCEEDED" | "FAILED";
export const WebhookCallStatusKnownValues: WebhookCallStatusKnownValue[] = [ "ATTEMPTING","SUCCEEDED","FAILED" ];
export type JwkAlgorithmType = "RSA256" | "FUTURE_VALUE";
export type JwkAlgorithmTypeKnownValue = "RSA256";
export const JwkAlgorithmTypeKnownValues: JwkAlgorithmTypeKnownValue[] = [ "RSA256" ];



export type CreateWebhookInput = { url: string; enabledTypes: Array<WebhookEventType> }
export type UpdateWebhookInput = { url: string; enabledTypes: Array<WebhookEventType> }
export type JwkSourceJwksUrlInput = { url: UrlAddress }
export type JwkSourceJwkPublicKeyInput = { key: RSAPublicKey; algorithmType: JwkAlgorithmType }
export type JwkSourceInput = { jwksUrl?: Maybe<JwkSourceJwksUrlInput>; jwkPublicKey?: Maybe<JwkSourceJwkPublicKeyInput> }
export type CreateOAuthClientInput = { displayName: string; externalJwkSource: JwkSourceInput }
export type UpdateOAuthClientInput = { displayName?: Maybe<string>; externalJwkSource?: Maybe<JwkSourceInput> }
export type WebhookEventsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; type?: Maybe<WebhookEventType> }
export type SendHelpMessageInput = { message: string }
export type CreateCopilotApiDeveloperInput = { email: EmailAddress }
export type UpdateCopilotApiDeveloperSettingsInput = { dummy?: Maybe<string> }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "JwkSource": [
    "JwkSourceJwksUrl",
    "JwkSourceJwkPublicKey"
  ]
};
export type PossibleTypes = {
  JwkSource: ("JwkSourceJwksUrl" | "JwkSourceJwkPublicKey")
};

export type EntityName = 
  | "CopilotApiDeveloper"
  | "Organization"
  | "SubOrganization"
  | "CopilotApiUsersWithActivity"
  | "Webhook"
  | "WebhookEvent"
  | "WebhookCall"
  | "WebhookCallFailure"
  | "OAuthClient"
export type EntityFieldMap = {
  CopilotApiDeveloper: "uuid" | "createdAt" | "organization" | "subOrganization" | "email" | "permissions" | "locale" | "timezone";
  Organization: "uuid" | "stringId" | "displayName" | "product" | "oauthClients" | "alertEmails";
  SubOrganization: "uuid" | "organization" | "displayName" | "timezone" | "copilotApiDevelopers";
  CopilotApiUsersWithActivity: "uuid" | "externalId" | "activationDaysCount";
  Webhook: "uuid" | "url" | "signatureSecret" | "createdAt" | "enabledTypes";
  WebhookEvent: "uuid" | "type" | "createdAt" | "status" | "calls";
  WebhookCall: "uuid" | "createdAt" | "status" | "lastAttemptedAt" | "numFailures" | "nextAttemptAt" | "webhook" | "failures";
  WebhookCallFailure: "uuid" | "exceptionText" | "httpStatusCode" | "httpResponse" | "createdAt";
  OAuthClient: "uuid" | "displayName" | "createdAt" | "lastUsedAt" | "externalJwkSource"
}
export type QueryVariablesMap = { copilotApiUsersActivityStats: "start" | "end"; webhookEvent: "uuid"; webhookEvents: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "type" }

export type QueryName = 
  | "GetWebhookSettings"
  | "GetWebhooks"
  | "GetWebhookEvent"
  | "GetWebhookEvents"
  | "MeWithCopilotApiDevelopers"
  | "CopilotApiUsersActivityStats"
  | "Me"
  | "CurrentCopilotApiDeveloper"

const WebhookFragmentDocument = gql`
fragment Webhook on Webhook {
  uuid
  url
  enabledTypes
  signatureSecret
}

`
export const WebhookFragmentProps: Fragment<WebhookFragment> = {
  document: WebhookFragmentDocument,
  fragmentName: "Webhook",
  entityName: "Webhook",
  __do_not_use_Data: null
}
export type WebhookFragment = { __typename: "Webhook"; uuid: UUID; url: string; enabledTypes: Array<WebhookEventType>; signatureSecret: string }

const WebhookCallFragmentDocument = gql`
fragment WebhookCall on WebhookCall {
  uuid
  createdAt
  status
  lastAttemptedAt
  numFailures
  nextAttemptAt
  webhook {
    ...Webhook
  }
  failures {
    uuid
    exceptionText
    httpStatusCode
    httpResponse
    createdAt
  }
}
${WebhookFragmentDocument}
`
export const WebhookCallFragmentProps: Fragment<WebhookCallFragment> = {
  document: WebhookCallFragmentDocument,
  fragmentName: "WebhookCall",
  entityName: "WebhookCall",
  __do_not_use_Data: null
}
export type WebhookCallFragment = {
  __typename: "WebhookCall";
  uuid: UUID;
  createdAt: ISOString;
  status: WebhookCallStatus;
  lastAttemptedAt: ISOString;
  numFailures: Int;
  nextAttemptAt: Maybe<ISOString>;
  webhook: WebhookFragment;
  failures: Array<{
  __typename: "WebhookCallFailure";
  uuid: UUID;
  exceptionText: Maybe<string>;
  httpStatusCode: Maybe<Int>;
  httpResponse: Maybe<string>;
  createdAt: ISOString
}>
}

const WebhookEventFragmentDocument = gql`
fragment WebhookEvent on WebhookEvent {
  uuid
  type
  createdAt
  status
}

`
export const WebhookEventFragmentProps: Fragment<WebhookEventFragment> = {
  document: WebhookEventFragmentDocument,
  fragmentName: "WebhookEvent",
  entityName: "WebhookEvent",
  __do_not_use_Data: null
}
export type WebhookEventFragment = { __typename: "WebhookEvent"; uuid: UUID; type: WebhookEventType; createdAt: ISOString; status: WebhookCallStatus }

const CopilotApiDeveloperSummaryFragmentDocument = gql`
fragment CopilotApiDeveloperSummary on CopilotApiDeveloper {
  uuid
  email
  locale
  timezone
}

`
export const CopilotApiDeveloperSummaryFragmentProps: Fragment<CopilotApiDeveloperSummaryFragment> = {
  document: CopilotApiDeveloperSummaryFragmentDocument,
  fragmentName: "CopilotApiDeveloperSummary",
  entityName: "CopilotApiDeveloper",
  __do_not_use_Data: null
}
export type CopilotApiDeveloperSummaryFragment = { __typename: "CopilotApiDeveloper"; uuid: UUID; email: EmailAddress; locale: SupportedLocale; timezone: TimeZone }

export type SendFakeWebhookEventData = { __typename: "SendFakeWebhookEventOutput"; webhookCall: WebhookCallFragment }
export type SendFakeWebhookEventVariables = { webhookUuid: UUID; eventType: WebhookEventType; eventDataJson: string }
export const SendFakeWebhookEvent: Mutation<SendFakeWebhookEventData, SendFakeWebhookEventVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation SendFakeWebhookEvent($webhookUuid: UUID!, $eventType: WebhookEventType!, $eventDataJson: String!) {
  sendFakeWebhookEvent(
    webhookUuid: $webhookUuid
    eventType: $eventType
    eventDataJson: $eventDataJson
  ) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "sendFakeWebhookEvent",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookSettingsData = { __typename: "MeOutput"; developer: { __typename: "CopilotApiDeveloper"; organization: { __typename: "Organization"; alertEmails: Array<EmailAddress> } } }
export type GetWebhookSettingsVariables = { [key: string]: never }
export const GetWebhookSettings: Query<GetWebhookSettingsData, GetWebhookSettingsVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query GetWebhookSettings {
  me {
    developer {
      organization {
        alertEmails
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetWebhooksData = Array<WebhookFragment>
export type GetWebhooksVariables = { [key: string]: never }
export const GetWebhooks: Query<GetWebhooksData, GetWebhooksVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query GetWebhooks {
  webhooks {
    ...Webhook
  }
}
${WebhookFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteWebhookData = MutationStatus
export type DeleteWebhookVariables = { uuid: UUID }
export const DeleteWebhook: Mutation<DeleteWebhookData, DeleteWebhookVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation DeleteWebhook($uuid: UUID!) {
  deleteWebhook(uuid: $uuid)
}

`,
  endpointName: "deleteWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateWebhookData = { __typename: "CreateWebhookOutput"; webhook: WebhookFragment }
export type CreateWebhookVariables = { input: CreateWebhookInput }
export const CreateWebhook: Mutation<CreateWebhookData, CreateWebhookVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "createWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateWebhookData = { __typename: "UpdateWebhookOutput"; webhook: WebhookFragment }
export type UpdateWebhookVariables = { uuid: UUID; input: UpdateWebhookInput }
export const UpdateWebhook: Mutation<UpdateWebhookData, UpdateWebhookVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation UpdateWebhook($uuid: UUID!, $input: UpdateWebhookInput!) {
  updateWebhook(uuid: $uuid, input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "updateWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateOAuthClientData = { __typename: "CreateOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type CreateOAuthClientVariables = { input: CreateOAuthClientInput }
export const CreateOAuthClient: Mutation<CreateOAuthClientData, CreateOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation CreateOAuthClient($input: CreateOAuthClientInput!) {
  createOAuthClient(input: $input) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "createOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateOAuthClientData = { __typename: "UpdateOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type UpdateOAuthClientVariables = { oauthClientUuid: UUID; input: UpdateOAuthClientInput }
export const UpdateOAuthClient: Mutation<UpdateOAuthClientData, UpdateOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation UpdateOAuthClient($oauthClientUuid: UUID!, $input: UpdateOAuthClientInput!) {
  updateOAuthClient(oauthClientUuid: $oauthClientUuid, input: $input) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "updateOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookEventData = { __typename: "WebhookEventOutput"; webhookEvent: WebhookEventFragment&{ calls: Array<WebhookCallFragment> } }
export type GetWebhookEventVariables = { uuid: UUID }
export const GetWebhookEvent: Query<GetWebhookEventData, GetWebhookEventVariables, true, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query GetWebhookEvent($uuid: UUID!) {
  webhookEvent(uuid: $uuid) {
    webhookEvent {
      ...WebhookEvent
      calls {
        ...WebhookCall
      }
    }
  }
}
${WebhookEventFragmentDocument}
${WebhookCallFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type RetryWebhookCallData = { __typename: "RetryWebhookCallOutput"; webhookCall: WebhookCallFragment }
export type RetryWebhookCallVariables = { uuid: UUID }
export const RetryWebhookCall: Mutation<RetryWebhookCallData, RetryWebhookCallVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation RetryWebhookCall($uuid: UUID!) {
  retryWebhookCall(webhookCallUuid: $uuid) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "retryWebhookCall",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookEventsData = { __typename: "WebhookEventsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<WebhookEventFragment> }
export type GetWebhookEventsVariables = { filters?: Maybe<WebhookEventsFilter>; cursor?: Maybe<string> }
export const GetWebhookEvents: Query<GetWebhookEventsData, GetWebhookEventsVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query GetWebhookEvents($filters: WebhookEventsFilter, $cursor: String) {
  webhookEvents(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...WebhookEvent
    }
  }
}
${WebhookEventFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type SendCopilotApiUserHelpMessageData = { __typename: "SendHelpMessageOutput"; status: MutationStatus }
export type SendCopilotApiUserHelpMessageVariables = { input: SendHelpMessageInput }
export const SendCopilotApiUserHelpMessage: Mutation<SendCopilotApiUserHelpMessageData, SendCopilotApiUserHelpMessageVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation SendCopilotApiUserHelpMessage($input: SendHelpMessageInput!) {
  sendHelpMessage(input: $input) {
    status
  }
}

`,
  endpointName: "sendHelpMessage",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateCopilotApiDeveloperData = { __typename: "CreateCopilotApiDeveloperOutput"; developer: { __typename: "CopilotApiDeveloper"; uuid: UUID; email: EmailAddress; createdAt: ISOString } }
export type CreateCopilotApiDeveloperVariables = { input: CreateCopilotApiDeveloperInput }
export const CreateCopilotApiDeveloper: Mutation<CreateCopilotApiDeveloperData, CreateCopilotApiDeveloperVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation CreateCopilotApiDeveloper($input: CreateCopilotApiDeveloperInput!) {
  createCopilotApiDeveloper(input: $input) {
    developer {
      uuid
      email
      createdAt
    }
  }
}

`,
  endpointName: "createCopilotApiDeveloper",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type MeWithCopilotApiDevelopersData = { __typename: "MeOutput"; developer: { __typename: "CopilotApiDeveloper"; uuid: UUID; subOrganization: { __typename: "SubOrganization"; uuid: UUID; copilotApiDevelopers: { __typename: "CopilotApiDevelopersSearchResult"; totalCount: Int; resultPage: { __typename: "CopilotApiDevelopersResultPage"; data: Array<{ __typename: "CopilotApiDeveloper"; uuid: UUID; email: EmailAddress; createdAt: ISOString }> } } } } }
export type MeWithCopilotApiDevelopersVariables = { [key: string]: never }
export const MeWithCopilotApiDevelopers: Query<MeWithCopilotApiDevelopersData, MeWithCopilotApiDevelopersVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query MeWithCopilotApiDevelopers {
  me {
    developer {
      uuid
      subOrganization {
        uuid
        copilotApiDevelopers {
          totalCount
          resultPage(page: {numberOfItems: -1}) {
            data {
              uuid
              email
              createdAt
            }
          }
        }
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type CopilotApiUsersActivityStatsData = { __typename: "CopilotApiUserActivityStatsOutput"; copilotApiUsersWithActivity: Array<{ __typename: "CopilotApiUsersWithActivity"; uuid: UUID; externalId: Maybe<string>; activationDaysCount: Int }> }
export type CopilotApiUsersActivityStatsVariables = { start: ISOString; end: ISOString }
export const CopilotApiUsersActivityStats: Query<CopilotApiUsersActivityStatsData, CopilotApiUsersActivityStatsVariables, true, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query CopilotApiUsersActivityStats($start: DateTime!, $end: DateTime!) {
  copilotApiUsersActivityStats(start: $start, end: $end) {
    copilotApiUsersWithActivity {
      uuid
      externalId
      activationDaysCount
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type MeData = { __typename: "MeOutput"; developer: { __typename: "CopilotApiDeveloper"; uuid: UUID; organization: { __typename: "Organization"; uuid: UUID; oauthClients: Array<{
  __typename: "OAuthClient";
  uuid: UUID;
  displayName: string;
  createdAt: ISOString;
  lastUsedAt: Maybe<ISOString>;
  externalJwkSource: ({ __typename: "JwkSourceJwksUrl"; url: UrlAddress } | { __typename: "JwkSourceJwkPublicKey"; key: RSAPublicKey; algorithmType: JwkAlgorithmType } | { __typename: "FutureValue" })
}> } } }
export type MeVariables = { [key: string]: never }
export const Me: Query<MeData, MeVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query Me {
  me {
    developer {
      uuid
      organization {
        uuid
        oauthClients {
          uuid
          displayName
          createdAt
          lastUsedAt
          externalJwkSource {
            ... on JwkSourceJwksUrl {
              url
            }
            ... on JwkSourceJwkPublicKey {
              key
              algorithmType
            }
          }
        }
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteOAuthClientData = { __typename: "DeleteOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type DeleteOAuthClientVariables = { oauthClientUuid: UUID }
export const DeleteOAuthClient: Mutation<DeleteOAuthClientData, DeleteOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation DeleteOAuthClient($oauthClientUuid: UUID!) {
  deleteOAuthClient(oauthClientUuid: $oauthClientUuid) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "deleteOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateSettingsData = { __typename: "UpdateSettingsCopilotApiOutput"; developer: CopilotApiDeveloperSummaryFragment }
export type UpdateSettingsVariables = { input: UpdateCopilotApiDeveloperSettingsInput }
export const UpdateSettings: Mutation<UpdateSettingsData, UpdateSettingsVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation UpdateSettings($input: UpdateCopilotApiDeveloperSettingsInput!) {
  updateSettings(input: $input) {
    developer {
      ...CopilotApiDeveloperSummary
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`,
  endpointName: "updateSettings",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CurrentCopilotApiDeveloperData = { __typename: "MeOutput"; developer: CopilotApiDeveloperSummaryFragment&{ permissions: Array<Permission>; subOrganization: { __typename: "SubOrganization"; uuid: UUID; displayName: Maybe<string>; timezone: TimeZone; organization: { __typename: "Organization"; uuid: UUID; stringId: string; displayName: string } } } }
export type CurrentCopilotApiDeveloperVariables = { [key: string]: never }
export const CurrentCopilotApiDeveloper: Query<CurrentCopilotApiDeveloperData, CurrentCopilotApiDeveloperVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query CurrentCopilotApiDeveloper {
  me {
    developer {
      ...CopilotApiDeveloperSummary
      permissions
      subOrganization {
        uuid
        displayName
        timezone
        organization {
          uuid
          stringId
          displayName
        }
      }
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}