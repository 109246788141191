import { createContext, useContext } from "react";

import { AccountJwt, SessionLoginMethod } from "api/jwt";
import { RequestContext } from "api/types";

import { Maybe } from "../base-types";

export type IdentityType = "COPILOT_API_DEVELOPER";
export type Identity<T extends IdentityType> = { type: T; uuid: UUID };

export const AuthErrorCode = {
  LOGIN_FAILURE: 1,
  INVALID_REFRESH_TOKEN: 2,
  ACCESS_TOKEN_REFRESH_FAILURE: 3,
  UNEXPECTED_TOKENS_STORAGE_FAILURE: 4,
};

/**
 * Errors related to authentication.
 *
 * When such an error bubbles up to the `ErrorBoundary` or the `ErrorPage`, we
 * will show a special human-readable message with a logout button.
 */
export class AuthError extends Error {
  code: number;

  constructor(message: string, code: number, cause?: any) {
    super(message, { cause });
    this.code = code;
  }
}

export type AccountLoginWithPasswordPayload = {
  email: string;
  password: string;
  mfaCode?: string;
};

export type AccountLoginWithTokensPayload = {
  accountAccessToken: string;
  accountRefreshToken: string;
};

export type StartAccountLoginWithGooglePayload = {
  emailLoginHint?: string;
  redirectPath?: string;
  requestCalendarEventsAccess?: boolean;
};

export type StartGoogleAuthorizationPayload = {
  redirectPath?: string;
  requestCalendarEventsAccess?: boolean;
};

export type CompleteGoogleLoginOrAuthorizationPayload = {
  code: string | null;
  state: string | null;
};

export type SetupMfaByTotpPayload = {
  tentativeQrCode: string;
};

export type SetupMfaByPhonePayload = {
  accountEmail: string;
  tentativePhoneNumber: Maybe<string>;
  antiAbuseToken: string;
};

// At least one of the MFA methods should be available.
export type SetupMfaByTotpOrPhonePayload =
  | {
      mfaByTotp: SetupMfaByTotpPayload;
      mfaByPhone?: SetupMfaByPhonePayload;
    }
  | {
      mfaByTotp?: SetupMfaByTotpPayload;
      mfaByPhone: SetupMfaByPhonePayload;
    };

export type VerifyMfaByPhonePayload = {
  accountEmail: string;
  phoneNumber: string;
  antiAbuseToken: string;
};

// At least one of the MFA methods should be available.
export type VerifyMfaByTotpOrPhonePayload =
  | {
      mfaByTotp: true;
      mfaByPhone?: VerifyMfaByPhonePayload;
    }
  | {
      mfaByTotp?: true;
      mfaByPhone: VerifyMfaByPhonePayload;
    };

export type LoginWithPasswordResult =
  | { kind: "SUCCESS" }
  | ({ kind: "MFA_REQUIRED" } & VerifyMfaByTotpOrPhonePayload)
  // Errors will already have been handled and displayed by the AuthProvider.
  | { kind: "CREDENTIALS_FAILURE" }
  | { kind: "MFA_FAILURE" }
  | { kind: "OTHER_FAILURE" };

export type StartAccountLoginWithGoogleResult =
  | { kind: "SUCCESS"; authenticationUrl: string }
  | { kind: "FAILURE" };

export type StartGoogleAuthorizationResult =
  | { kind: "SUCCESS" }
  | { kind: "AUTHORIZATION_REQUIRED"; authorizationUrl: string }
  | { kind: "FAILURE" };

export type CompleteGoogleLoginOrAuthorizationResult =
  | { kind: "SUCCESS"; redirectPath?: string }
  | { kind: "FAILURE"; redirectPath?: string };

export type AuthContextType = {
  accountLoginWithTokens: (payload: AccountLoginWithTokensPayload) => void;
  accountLoginWithPassword: (
    payload: AccountLoginWithPasswordPayload,
  ) => Promise<LoginWithPasswordResult>;
  startAccountLoginWithGoogle: (
    payload: StartAccountLoginWithGooglePayload,
  ) => Promise<StartAccountLoginWithGoogleResult>;

  // Should be called once Google redirects back to the console after the
  // login or authorization flow initiated by `startAccountLoginWithGoogle`
  // or `startGoogleAuthorization` respectively.
  completeGoogleLoginOrAuthorization: (
    payload: CompleteGoogleLoginOrAuthorizationPayload,
  ) => Promise<CompleteGoogleLoginOrAuthorizationResult>;
} & AuthState;

export type AuthState = LoggedOutAuthState | LoggedInAuthState;

export type LoggedOutAuthState = {
  state: "LOGGED_OUT";
};

export type LoggedInAuthState = {
  state: "LOGGED_IN";
  logout: () => Promise<void>;
  currentSessionLoginMethod: SessionLoginMethod;

  currentImpersonationUuid?: UUID;
  setCurrentImpersonationUuid: (newImpersonationUuid?: UUID) => void;
} & LoggedInWithAccountTokensAuthState;

export type LoggedInWithAccountTokensAuthState = {
  accountRequestContext: RequestContext<"AUTHENTICATED_AS_ACCOUNT">;

  // Users can pick any doctor or superuser identity owned by the account.
  // Note: this is a low-level primitive which doesn't check whether `identity`
  // is compatible with the current login method. Prefer using `usePickIdentity`
  // unless you're very sure about what you're doing.
  setCurrentIdentity: (identity: Identity<IdentityType> | null) => void;

  // Should only be used to export tokens to other front-ends (e.g. Copilot).
  getLatestAccountTokens: () => {
    accessToken: AccountJwt;
    refreshToken: AccountJwt;
  };
} & (
  | { currentIdentity: undefined }
  | {
      currentIdentity: Identity<"COPILOT_API_DEVELOPER">;
      copilotApiUserRequestContext: RequestContext<"AUTHENTICATED_AS_COPILOT_API_DEVELOPER">;
    }
);

export const AuthContext = createContext<AuthContextType | null>(null);
AuthContext.displayName = "AuthContext";

export const useMaybeAuth = () => useContext(AuthContext);

export const useAuth = () => {
  const auth = useMaybeAuth();
  if (!auth) throw new Error("No AuthProvider in context.");
  return auth;
};

export const useLoggedInAuth = () => {
  const auth = useAuth();
  if (auth.state !== "LOGGED_IN") throw new Error("Expecting to be logged in.");
  return auth;
};
