import { ReactNode, useCallback, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "capacitor-updater";

import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { defaultOrigin } from "utils/environment";
import { notifier } from "utils/notifier";
import versions from "versions.json";

import { AppVersionContext, AppVersionState } from "./AppVersionContext";

// WARNING:
// This component do not really track available versions anymore.
// It is left here so we don't need to change the rest of the (old) codebase.
export const AppVersionProvider = ({ children }: { children: ReactNode }) => {
  const t = useTranslation();

  const [minimumCodeVersion, setMinimumCodeVersion] = useState(0);
  const minimumCodeVersionRef = useSyncRef(minimumCodeVersion);
  const updateMinimumCodeVersionIfNeeded = useCallback(
    (version: number) => {
      if (version <= minimumCodeVersionRef.current) return;
      setMinimumCodeVersion(version);
    },
    [minimumCodeVersionRef],
  );
  const [downloadingUpdate, setDownloadingUpdate] = useState(false);
  const [availableCodeVersion] = useState<number>();
  const currentCodeVersion = versions.code_version;

  const state: AppVersionState = (() => {
    // No API call made yet
    if (!minimumCodeVersion) return "NONE";
    // Technically complexe: we got an API call before getAvailableCodeVersion, just wait for the json fetch to end
    if (!availableCodeVersion) return "NONE";

    if (currentCodeVersion < minimumCodeVersion) {
      // We are outdated
      return availableCodeVersion < minimumCodeVersion
        ? // There is no satisfactory version out yet.
          // This is the case when the backend deployed but the frontend is still deploying.
          "RELEASING"
        : // Compatible version is available
        currentCodeVersion < minimumCodeVersion
        ? "OUTDATED" // This is breaking change
        : "UNKNOWN_ENUM_USED"; // Some data is not correctly handle
    }

    // The code we are running is sufficiently up-to-date, but we hint to update if possible
    return currentCodeVersion === availableCodeVersion
      ? "NONE" // Running last version
      : "UPDATE_AVAILABLE";
  })();

  // Do not try to fetch versions.json anymore
  // useEffect(() => {
  //   const interval = state === "RELEASING" ? 5_000 : 10 * 60_000;
  //   const getAvailableVersion = () => {
  //     axios
  //       .get<typeof versions>(
  //         `${Capacitor.isNativePlatform() ? defaultOrigin : ""}/versions.json`,
  //       )
  //       .then(({ data }) => {
  //         setAvailableCodeVersion(data.code_version);
  //         updateMinimumCodeVersionIfNeeded(data.doctor_min_version);
  //       })
  //       .catch(() => null); // If the request fails, we just wait for the next call
  //   };
  //   const intervalId = setInterval(() => {
  //     getAvailableVersion();
  //   }, interval);
  //   getAvailableVersion();
  //   return () => clearInterval(intervalId);
  // }, [updateMinimumCodeVersionIfNeeded, setAvailableCodeVersion, state]);

  const downloadUpdate = () => {
    setDownloadingUpdate(true);
    CapacitorUpdater.download({
      url: `${defaultOrigin}/build.zip`,
    })
      .then((result) => CapacitorUpdater.set(result))
      .then(() => setDownloadingUpdate(false))
      .catch(() => {
        setDownloadingUpdate(false);
        notifier.error({
          user: t("app_version.app_version_provider.download_failed"),
        });
      });
  };

  return (
    <AppVersionContext.Provider
      value={{
        state,
        minimumCodeVersion,
        updateMinimumCodeVersionIfNeeded,
        downloadingUpdate,
        availableCodeVersion,
        reload: () => {
          if (Capacitor.isNativePlatform()) {
            downloadUpdate();
          } else {
            window.location.reload();
          }
        },
        downloadUpdateIfOutdated: () => {
          if (Capacitor.isNativePlatform() && state === "OUTDATED") {
            downloadUpdate();
          }
        },
      }}
    >
      {children}
    </AppVersionContext.Provider>
  );
};
