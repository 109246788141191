import {
  WebhookEventType,
  WebhookEventTypeKnownValues,
} from "generated/copilot-api-developer";
import { isKnownValue } from "utils/enum";

export const webhookEventTypeString = (value: WebhookEventType) => {
  if (!isKnownValue(value, WebhookEventTypeKnownValues)) {
    return "__unknown_type__";
  }

  switch (value) {
    case "GENERATE_NOTE_ASYNC_SUCCEEDED":
      return "generate_note_async.succeeded";
    case "GENERATE_NOTE_ASYNC_FAILED":
      return "generate_note_async.failed";
    case "TRANSCRIBE_ASYNC_SUCCEEDED":
      return "transcribe_async.succeeded";
    case "TRANSCRIBE_ASYNC_FAILED":
      return "transcribe_async.failed";
    case "DICTATE_ASYNC_SUCCEEDED":
      return "dictate_async.succeeded";
    case "DICTATE_ASYNC_FAILED":
      return "dictate_async.failed";
  }
};
