import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Table } from "components/Table/Table";
import { MeWithCopilotApiDevelopers } from "generated/copilot-api-developer";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";

import { CreateApiAdmin } from "./CreateApiAdmin";

gql`
  # schema = COPILOT_API_DEVELOPER
  query MeWithCopilotApiDevelopers {
    me {
      developer {
        uuid
        subOrganization {
          uuid
          copilotApiDevelopers {
            totalCount
            resultPage(page: { numberOfItems: -1 }) {
              data {
                uuid
                email
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

export const ApiAdmins = () => {
  const t = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data, refetch } = useQuery(MeWithCopilotApiDevelopers);
  const copilotApiDevelopers =
    data?.developer.subOrganization.copilotApiDevelopers.resultPage.data ?? [];

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <div className="flex items-center">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.api_admins.table_title")}
          </h1>

          <Button
            label={t("developers.api_admins.add_admin_button_label")}
            onClick={() => setIsCreateModalOpen(true)}
            className="ml-auto"
          />
        </div>
      </div>
      {isCreateModalOpen && (
        <CreateApiAdmin
          onClose={() => {
            setIsCreateModalOpen(false);
            refetch();
          }}
        />
      )}
      <Table
        elements={copilotApiDevelopers}
        fieldHeaders={[
          t("developers.api_admins.email_table_field"),
          t("developers.api_admins.created_at_table_field"),
        ]}
        fields={(copilotApiDeveloper) => [
          copilotApiDeveloper.email,
          copilotApiDeveloper.createdAt.format("date"),
        ]}
      />
    </Background>
  );
};
