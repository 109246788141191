import { ApiError } from "api/api-client";
import { AuthError, AuthErrorCode, useAuth } from "auth/AuthContext";
import { Button } from "components/Button/Button";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { useLogout } from "contexts/User/useLogout";
import { AppErrorCode } from "errors/generated";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useTranslation } from "i18n";
import { isDef } from "utils";

import { MfaRequired } from "./MfaRequired";
import { NotFound } from "./NotFound";

type FormattedOrUnknownError =
  | string
  | JSX.Element
  | ParsedGraphQLError
  | null
  | undefined;

export const ErrorPage = ({ error }: { error: FormattedOrUnknownError }) => (
  <div className="flex-fill p-20 overflow-auto flex-col flex-center text-center">
    <ErrorPageContent error={error} />
  </div>
);

const ErrorPageContent = ({ error }: { error: FormattedOrUnknownError }) => {
  const t = useTranslation();
  const { state } = useAuth();
  const [logout, loggingOut] = useLogout();
  const { reload } = useAppVersion();

  if (isGraphQLError(error) && error.code === AppErrorCode.ENTITY_NOT_FOUND) {
    return <NotFound />;
  }

  if (apiError(error)?.response?.data.code === AppErrorCode.MFA_IS_REQUIRED) {
    return <MfaRequired />;
  }

  const hasSessionExpired =
    authError(error)?.code === AuthErrorCode.INVALID_REFRESH_TOKEN;

  const getErrorMessage = () => {
    if (hasSessionExpired) return t("error_page.error_page.session_expired");

    if (isGraphQLError(error)) return error.display;

    return error ?? t("error_page.error_page.unknown_error");
  };

  return (
    <>
      <div className="text-danger text-center">{getErrorMessage()}</div>
      {isGraphQLError(error) && error.requestId && !error.expected && (
        <div className="text-10 mt-4">Request ID: {error.requestId}</div>
      )}
      <div className="mt-16 flex space-x-16">
        {!hasSessionExpired && (
          <Button
            label={t("error_page.error_page.back_to_home")}
            className="bg-white text-primary"
            to="/"
          />
        )}
        {!hasSessionExpired && (
          <Button
            label={t("error_page.error_page.reload")}
            onClick={() => reload()}
          />
        )}
        {state !== "LOGGED_OUT" && (
          <Button
            label={t("error_page.error_page.logout")}
            danger
            loading={loggingOut}
            onClick={() => logout()}
          />
        )}
      </div>
    </>
  );
};

const isGraphQLError = (
  error: FormattedOrUnknownError,
): error is ParsedGraphQLError =>
  isDef(error) && typeof error === "object" && "display" in error;

const apiError = (error: FormattedOrUnknownError): ApiError | null => {
  if (!(error instanceof ParsedGraphQLError)) return null;
  const networkError = error.extendedApolloError?.networkError;
  if (!(networkError instanceof ApiError)) return null;
  return networkError;
};

const authError = (error: FormattedOrUnknownError): AuthError | null => {
  if (!(error instanceof ParsedGraphQLError)) return null;
  const networkError = error.extendedApolloError?.networkError;
  if (!(networkError instanceof AuthError)) return null;
  return networkError;
};
