import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Modal } from "components/Modal/Modal";
import { CreateCopilotApiDeveloper } from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = COPILOT_API_DEVELOPER
  mutation CreateCopilotApiDeveloper($input: CreateCopilotApiDeveloperInput!) {
    createCopilotApiDeveloper(input: $input) {
      developer {
        uuid
        email
        createdAt
      }
    }
  }
`;

type FormValues = {
  email: string;
};

export const CreateApiAdmin = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslation();

  const [createCopilotApiDeveloper] = useMutation(CreateCopilotApiDeveloper, {
    onSuccess: () => {
      notifier.success(
        t("developers.api_admins.create.successful_creation_notification"),
      );
      onClose();
    },
  });

  return (
    <Modal
      title={t("developers.api_admins.create.modal_title")}
      onHide={onClose}
    >
      <Form<FormValues>
        className="flex-col w-full mt-24 space-y-24"
        initialValues={{
          email: "",
        }}
        onSubmit={({ email }) =>
          createCopilotApiDeveloper({
            input: {
              email,
            },
          })
        }
      >
        <FormInput
          name="email"
          label={t("developers.api_admins.create.email_field_label")}
          placeholder="example@gmail.com"
          wrapperClassName="flex-fill"
        />

        <Submit
          className="mt-36"
          label={t("developers.api_admins.create.create_button_label")}
        />
      </Form>
    </Modal>
  );
};
