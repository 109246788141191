// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type EmptyObject = "EMPTY" | "FUTURE_VALUE";
export type EmptyObjectKnownValue = "EMPTY";
export const EmptyObjectKnownValues: EmptyObjectKnownValue[] = [ "EMPTY" ];
export type CustomerEnvironment = "PRODUCTION" | "TEST" | "FUTURE_VALUE";
export type CustomerEnvironmentKnownValue = "PRODUCTION" | "TEST";
export const CustomerEnvironmentKnownValues: CustomerEnvironmentKnownValue[] = [ "PRODUCTION","TEST" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH" ];
export type NablaProduct = "ASSISTANT" | "CORE_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "ASSISTANT" | "CORE_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "ASSISTANT","CORE_API" ];
export type IdentityKind = "COPILOT_ASSISTANT_USER" | "COPILOT_API_DEVELOPER" | "FUTURE_VALUE";
export type IdentityKindKnownValue = "COPILOT_ASSISTANT_USER" | "COPILOT_API_DEVELOPER";
export const IdentityKindKnownValues: IdentityKindKnownValue[] = [ "COPILOT_ASSISTANT_USER","COPILOT_API_DEVELOPER" ];
export type LoginMethod = "PASSWORD_WITHOUT_MFA" | "PASSWORD_WITH_MFA" | "ONE_TIME_TOKEN_WITHOUT_MFA" | "ONE_TIME_TOKEN_WITH_MFA" | "EPIC_SSO" | "CERNER_SSO" | "ATHENA_SSO" | "REDROVER_SSO" | "SAML_SSO" | "INITIAL_CREATION" | "ORGANIZATION_INITIATED" | "ASSISTANT_USER_INITIATED_LOW_PRIVILEGE" | "FUTURE_VALUE";
export type LoginMethodKnownValue = "PASSWORD_WITHOUT_MFA" | "PASSWORD_WITH_MFA" | "ONE_TIME_TOKEN_WITHOUT_MFA" | "ONE_TIME_TOKEN_WITH_MFA" | "EPIC_SSO" | "CERNER_SSO" | "ATHENA_SSO" | "REDROVER_SSO" | "SAML_SSO" | "INITIAL_CREATION" | "ORGANIZATION_INITIATED" | "ASSISTANT_USER_INITIATED_LOW_PRIVILEGE";
export const LoginMethodKnownValues: LoginMethodKnownValue[] = [ "PASSWORD_WITHOUT_MFA","PASSWORD_WITH_MFA","ONE_TIME_TOKEN_WITHOUT_MFA","ONE_TIME_TOKEN_WITH_MFA","EPIC_SSO","CERNER_SSO","ATHENA_SSO","REDROVER_SSO","SAML_SSO","INITIAL_CREATION","ORGANIZATION_INITIATED","ASSISTANT_USER_INITIATED_LOW_PRIVILEGE" ];
export type MfaMethodKind = "TOTP" | "SMS" | "FUTURE_VALUE";
export type MfaMethodKindKnownValue = "TOTP" | "SMS";
export const MfaMethodKindKnownValues: MfaMethodKindKnownValue[] = [ "TOTP","SMS" ];
export type NablaRegion = "EU" | "US" | "FUTURE_VALUE";
export type NablaRegionKnownValue = "EU" | "US";
export const NablaRegionKnownValues: NablaRegionKnownValue[] = [ "EU","US" ];
export type CloudRegion = "EU_WEST1" | "US_CENTRAL1" | "FUTURE_VALUE";
export type CloudRegionKnownValue = "EU_WEST1" | "US_CENTRAL1";
export const CloudRegionKnownValues: CloudRegionKnownValue[] = [ "EU_WEST1","US_CENTRAL1" ];



export type SetupMfaInput = { code: string; methodKind: MfaMethodKind }
export type CopilotApiOrganizationInput = {
  id: string;
  displayName: string;
  locale: SupportedLocale;
  timezone: TimeZone;
  attributionData?: Maybe<string>;
  customerEnvironment?: Maybe<CustomerEnvironment>
}
export type InitialCopilotApiDeveloperIdentityInput = { _?: Maybe<EmptyObject> }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "Identity": [
    "CopilotAssistantUserIdentity",
    "CopilotApiDeveloperIdentity"
  ],
  "MfaState": [
    "NotSetupMfaState",
    "SetupMfaState"
  ],
  "MfaMethod": [
    "TotpMfaMethod",
    "SmsMfaMethod"
  ]
};
export type PossibleTypes = {
  Identity: ("CopilotAssistantUserIdentity" | "CopilotApiDeveloperIdentity")
  MfaState: ("NotSetupMfaState" | "SetupMfaState")
  MfaMethod: ("TotpMfaMethod" | "SmsMfaMethod")
};

export type EntityName = 
  | "Account"
  | "CopilotAssistantUserIdentity"
  | "CopilotApiDeveloperIdentity"
  | "CopilotAssistantUser"
  | "CopilotApiDeveloper"
  | "Organization"
  | "SubOrganization"
export type EntityFieldMap = {
  Account: "uuid" | "email" | "locale" | "timezone" | "identities" | "hasPassword" | "mfaState";
  CopilotAssistantUserIdentity: "uuid" | "kind" | "user" | "acceptableLoginMethods";
  CopilotApiDeveloperIdentity: "uuid" | "kind" | "developer" | "acceptableLoginMethods";
  CopilotAssistantUser: "uuid" | "organization" | "subOrganization" | "canAccessOrganizationUserApi";
  CopilotApiDeveloper: "uuid" | "organization" | "subOrganization";
  Organization: "uuid" | "stringId" | "displayName" | "product";
  SubOrganization: "uuid" | "organization" | "displayName" | "customerEnvironment"
}
export type QueryVariablesMap = {  }

export type QueryName = 
  | "AccountIdentities"
  | "GetFreshMfaBySmsAntiAbuseToken"

const AccountSubOrganizationSummaryFragmentDocument = gql`
fragment AccountSubOrganizationSummary on SubOrganization {
  uuid
  displayName
  organization {
    uuid
    stringId
    displayName
    product
  }
  customerEnvironment
}

`
export const AccountSubOrganizationSummaryFragmentProps: Fragment<AccountSubOrganizationSummaryFragment> = {
  document: AccountSubOrganizationSummaryFragmentDocument,
  fragmentName: "AccountSubOrganizationSummary",
  entityName: "SubOrganization",
  __do_not_use_Data: null
}
export type AccountSubOrganizationSummaryFragment = { __typename: "SubOrganization"; uuid: UUID; displayName: Maybe<string>; organization: { __typename: "Organization"; uuid: UUID; stringId: string; displayName: string; product: NablaProduct }; customerEnvironment: CustomerEnvironment }

const CopilotApiDeveloperSummaryFragmentDocument = gql`
fragment CopilotApiDeveloperSummary on CopilotApiDeveloper {
  uuid
  subOrganization {
    ...AccountSubOrganizationSummary
  }
}
${AccountSubOrganizationSummaryFragmentDocument}
`
export const CopilotApiDeveloperSummaryFragmentProps: Fragment<CopilotApiDeveloperSummaryFragment> = {
  document: CopilotApiDeveloperSummaryFragmentDocument,
  fragmentName: "CopilotApiDeveloperSummary",
  entityName: "CopilotApiDeveloper",
  __do_not_use_Data: null
}
export type CopilotApiDeveloperSummaryFragment = { __typename: "CopilotApiDeveloper"; uuid: UUID; subOrganization: AccountSubOrganizationSummaryFragment }

const IdentityFragmentDocument = gql`
fragment Identity on Identity {
  uuid
  acceptableLoginMethods
  ... on CopilotApiDeveloperIdentity {
    developer {
      ...CopilotApiDeveloperSummary
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`
export const IdentityFragmentProps: Fragment<IdentityFragment> = {
  document: IdentityFragmentDocument,
  fragmentName: "Identity",
  entityName: "Identity",
  __do_not_use_Data: null
}
export type IdentityFragment = { uuid: UUID; acceptableLoginMethods: Array<LoginMethod> }&({ __typename: "CopilotApiDeveloperIdentity"; developer: CopilotApiDeveloperSummaryFragment } | { __typename: "FutureValue" })

const IdentitiesFragmentDocument = gql`
fragment Identities on Account {
  uuid
  identities {
    ...Identity
  }
}
${IdentityFragmentDocument}
`
export const IdentitiesFragmentProps: Fragment<IdentitiesFragment> = {
  document: IdentitiesFragmentDocument,
  fragmentName: "Identities",
  entityName: "Account",
  __do_not_use_Data: null
}
export type IdentitiesFragment = { __typename: "Account"; uuid: UUID; identities: Array<IdentityFragment> }

export type AccountIdentitiesData = IdentitiesFragment&{ email: EmailAddress; locale: SupportedLocale; timezone: TimeZone; hasPassword: boolean; mfaState: ({ __typename: "SetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean } | { __typename: "SmsMfaMethod"; isSetup: boolean; phone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "NotSetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean; tentativeQrCode: string } | { __typename: "SmsMfaMethod"; isSetup: boolean; tentativePhone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" }) }
export type AccountIdentitiesVariables = { [key: string]: never }
export const AccountIdentities: Query<AccountIdentitiesData, AccountIdentitiesVariables, false, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
query AccountIdentities {
  me {
    ...Identities
    email
    locale
    timezone
    hasPassword
    mfaState {
      ... on SetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
          }
          ... on SmsMfaMethod {
            isSetup
            phone
            mfaBySmsAntiAbuseToken
          }
        }
      }
      ... on NotSetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
            tentativeQrCode
          }
          ... on SmsMfaMethod {
            isSetup
            tentativePhone
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
  }
}
${IdentitiesFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetFreshMfaBySmsAntiAbuseTokenData = { __typename: "Account"; mfaState: ({ __typename: "NotSetupMfaState"; supportedMethods: Array<({ __typename: "SmsMfaMethod"; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" }) }
export type GetFreshMfaBySmsAntiAbuseTokenVariables = { [key: string]: never }
export const GetFreshMfaBySmsAntiAbuseToken: Query<GetFreshMfaBySmsAntiAbuseTokenData, GetFreshMfaBySmsAntiAbuseTokenVariables, false, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
query GetFreshMfaBySmsAntiAbuseToken {
  me {
    mfaState {
      ... on NotSetupMfaState {
        supportedMethods {
          ... on SmsMfaMethod {
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type ChooseAccountCredentialsAuthenticatedData = { __typename: "ChooseAccountCredentialsOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type ChooseAccountCredentialsAuthenticatedVariables = { newPassword: string; verifyMfaCode?: Maybe<string> }
export const ChooseAccountCredentialsAuthenticated: Mutation<ChooseAccountCredentialsAuthenticatedData, ChooseAccountCredentialsAuthenticatedVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation ChooseAccountCredentialsAuthenticated($newPassword: String!, $verifyMfaCode: String) {
  chooseAccountCredentials(
    mfa: {verify: {code: $verifyMfaCode}}
    newPassword: $newPassword
  ) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "chooseAccountCredentials",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SetupMfaMethodData = { __typename: "SetupMfaMethodOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type SetupMfaMethodVariables = { mfaCode?: Maybe<string>; newMethod: SetupMfaInput }
export const SetupMfaMethod: Mutation<SetupMfaMethodData, SetupMfaMethodVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation SetupMfaMethod($mfaCode: String, $newMethod: SetupMfaInput!) {
  setupMfaMethod(mfaCode: $mfaCode, newMethod: $newMethod) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "setupMfaMethod",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateCopilotApiOrganizationData = { __typename: "CreateCopilotApiOrganizationOutput"; organization: { __typename: "Organization"; uuid: UUID }; initialIdentity: { __typename: "CopilotApiDeveloperIdentity"; uuid: UUID; acceptableLoginMethods: Array<LoginMethod>; developer: CopilotApiDeveloperSummaryFragment } }
export type CreateCopilotApiOrganizationVariables = { organizationInput: CopilotApiOrganizationInput; initialIdentityInput: InitialCopilotApiDeveloperIdentityInput }
export const CreateCopilotApiOrganization: Mutation<CreateCopilotApiOrganizationData, CreateCopilotApiOrganizationVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation CreateCopilotApiOrganization($organizationInput: CopilotApiOrganizationInput!, $initialIdentityInput: InitialCopilotApiDeveloperIdentityInput!) {
  createCopilotApiOrganization(
    organization: $organizationInput
    initialIdentity: $initialIdentityInput
  ) {
    organization {
      uuid
    }
    initialIdentity {
      uuid
      acceptableLoginMethods
      developer {
        ...CopilotApiDeveloperSummary
      }
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`,
  endpointName: "createCopilotApiOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}