import { CSSProperties } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Spinner } from "components/Spinner/Spinner";
import { useTranslation } from "i18n";

import { Input, InputProps } from "../Input/Input";
import { NamedLabelWrapperProps } from "../Label/LabelWrapper";
import { CustomOptionProps, OptionsPopover } from "./OptionsPopover";

export type SelectProps<
  Option,
  IsClearable extends boolean = false,
> = NamedLabelWrapperProps & {
  options: readonly Option[];
  isClearable?: IsClearable;
  creatable?: Option extends string ? boolean : never;
  value: Option | undefined;
  getOptionId?: (o: Option) => string;
  getOptionLabel?: (o: Option) => string;
  CustomOption?: (props: CustomOptionProps<Option>) => JSX.Element;
  onChange: (
    value: IsClearable extends true ? Option | undefined : Option,
  ) => void;
  className?: string;
  placeholder?: string;
  loading?: boolean;
  hideDropdownIndicator?: boolean;
  thinGray?: boolean;
  optionsStyle?: CSSProperties;
  position?: "top" | "bottom";
  onSearchChanged?: (value: string | undefined) => void;
} & Pick<
    InputProps,
    "style" | "autoFocus" | "leftInnerElement" | "disabled" | "required"
  >;

export const Select = <Option, IsClearable extends boolean = false>({
  options,
  isClearable,
  creatable,
  value,
  getOptionId = (o: Option) =>
    o && typeof o === "object" // Because null is an object, thanks JS
      ? // @ts-ignore
        o.uuid
      : o,
  getOptionLabel = (o: Option) => (o as any).toString(),
  CustomOption,
  onChange,
  placeholder,
  className,
  loading,
  hideDropdownIndicator,
  thinGray,
  optionsStyle,
  position,
  onSearchChanged,
  error,
  ...props
}: SelectProps<Option, IsClearable>) => {
  const t = useTranslation();
  return (
    <OptionsPopover<Option, false>
      options={options}
      value={value}
      onSelect={onChange as (value: Option | undefined) => void}
      getOptionId={getOptionId}
      getOptionLabel={getOptionLabel}
      CustomOption={CustomOption}
      loading={loading}
      creatable={creatable}
      position={position && [position]}
      style={optionsStyle}
      onSearchChanged={onSearchChanged}
    >
      {({
        inputRef,
        set,
        search,
        showOptions: _showOptions,
        openOptions,
        ...inputProps
      }) => (
        <Input
          inputRef={inputRef}
          error={error}
          value={search ?? (value === undefined ? "" : getOptionLabel(value))}
          {...inputProps}
          placeholder={placeholder ?? t("form.select.select.pick")}
          className={classNames(
            className,
            !hideDropdownIndicator && isClearable
              ? "pr-56"
              : !hideDropdownIndicator || isClearable
              ? "pr-32"
              : undefined,
          )}
          autoComplete="off"
          thinGray={thinGray}
          rightInnerElementClassName="space-x-6"
          rightInnerElement={
            <>
              {loading && <Spinner small inline />}
              {isClearable &&
                value !== undefined &&
                !loading &&
                !props.disabled && (
                  <ClickableIcon
                    name="close"
                    size={12}
                    className="p-4 hover:opacity-80"
                    onClick={() => {
                      set(undefined);
                    }}
                  />
                )}
              {!hideDropdownIndicator && !props.disabled && (
                <ClickableIcon
                  name="chevron"
                  rotate={90}
                  className="p-4 hover:opacity-80"
                  onClick={() => {
                    inputRef.current!.focus();
                    openOptions();
                  }}
                  tabIndex={-1}
                />
              )}
            </>
          }
          {...props}
        />
      )}
    </OptionsPopover>
  );
};
