import { ReactNode, useCallback, useMemo, useState } from "react";
import gql from "graphql-tag";
import { z } from "zod";

import { queryEndpoint } from "api/api-client";
import { REST_ENDPOINTS } from "api/endpoints";
import { AccountJwt, jwtRegion } from "api/jwt";
import { AUTH_TOKENS_KEY } from "consts";
import { AppErrorCode } from "errors/generated";
import {
  LoginWithPassword,
  Logout,
  Refresh,
} from "generated/unauth-copilot-api";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useUnauthMutation } from "graphql-client/useUnauthMutation";
import { useStableCallback } from "hooks/useStableCallback";
import { useStorageState } from "hooks/useStorageState";
import { staticT as t } from "i18n";
import { run } from "utils";
import { notifier } from "utils/notifier";

import {
  AccountLoginWithPasswordPayload,
  AccountLoginWithTokensPayload,
  AuthContext,
  AuthContextType,
  AuthError,
  AuthErrorCode,
  AuthState,
  CompleteGoogleLoginOrAuthorizationPayload,
  CompleteGoogleLoginOrAuthorizationResult,
  Identity,
  IdentityType,
  LoginWithPasswordResult,
  StartAccountLoginWithGooglePayload,
  StartAccountLoginWithGoogleResult,
} from "./AuthContext";
import {
  ExpiredTokenError,
  isTokenExpired,
  parseFreshAccountJwt,
} from "./utils";

gql`
  # schema = UNAUTHENTICATED_COPILOT_API_DEVELOPER
  mutation LoginWithPassword(
    $email: EmailAddress!
    $password: String!
    $mfaCode: String
  ) {
    loginWithPassword(email: $email, password: $password, mfaCode: $mfaCode) {
      loginResponse {
        ... on LoginResponseSuccess {
          jwtTokens {
            accessToken
            refreshToken
          }
        }
        ... on LoginResponseMfaRequired {
          mfaState {
            setupMethods {
              ... on TotpMfaMethod {
                isSetup
              }
              ... on SmsMfaMethod {
                isSetup
                phone
                mfaBySmsAntiAbuseToken
              }
            }
          }
        }
      }
    }
  }

  mutation Logout($refreshJwtToken: String!) {
    logout(refreshJwtToken: $refreshJwtToken) {
      _
    }
  }

  mutation Refresh($refreshJwtToken: String!) {
    refresh(refreshJwtToken: $refreshJwtToken) {
      jwtTokens {
        accessToken
        refreshToken
      }
    }
  }
`;

type StoredAccountTokensData = {
  accessToken: AccountJwt;
  refreshToken: AccountJwt;
  currentIdentity?: Identity<IdentityType>;
};

type StoredTokensData = StoredAccountTokensData;

// Keep in sync with `GoogleOAuthComponent.kt`.
const googleOAuthStateSchema = z.object({
  type: z.enum(["AUTHENTICATION", "AUTHORIZATION"]),
  redirectPath: z.string().nullish(),
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentImpersonationUuid, setCurrentImpersonationUuid] = useState<
    UUID | undefined
  >();
  const [storedTokens, setStoredTokens] =
    useStorageState<StoredTokensData | null>(AUTH_TOKENS_KEY, null);

  const storedTokenType = storedTokens?.refreshToken.type;
  const currentIdentityType = storedTokens?.currentIdentity?.type;
  const currentIdentityUuid = storedTokens?.currentIdentity?.uuid;
  const currentSessionLoginMethod =
    storedTokens?.refreshToken.payload.session_login_method;

  const getLatestTokens = useStableCallback(() => storedTokens);
  const [loginWithPasswordMutation] = useUnauthMutation(LoginWithPassword, {
    throwOnError: true,
  });
  const [logoutMutation] = useUnauthMutation(Logout, {
    throwOnError: true,
  });
  const [refreshMutation] = useUnauthMutation(Refresh, {
    throwOnError: true,
  });

  // ----- Utilities.

  // Refreshes the current account tokens and returns the new access token.
  const refreshAccountTokensOrThrow = useCallback(
    async (currentTokens: StoredAccountTokensData) => {
      const { refreshToken, currentIdentity } = currentTokens;
      const data = await refreshMutation(
        {
          refreshJwtToken: refreshToken.token,
        },
        {
          requestContext: { region: jwtRegion(refreshToken) },
        },
      );

      const newAccessToken = parseFreshAccountJwt(data.jwtTokens.accessToken);
      const newRefreshToken = parseFreshAccountJwt(data.jwtTokens.refreshToken);
      setStoredTokens({
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
        currentIdentity, // Keep using the current identity.
      });
      return newAccessToken;
    },
    [setStoredTokens, refreshMutation],
  );

  // Updates the current identity when logged in with account tokens.
  // Important: should not be called when logged in with doctor tokens.
  const setCurrentIdentity = useCallback(
    (currentIdentity: Identity<IdentityType> | null) => {
      setCurrentImpersonationUuid(undefined);
      setStoredTokens(
        (tokens) =>
          tokens &&
          ({
            ...tokens,
            currentIdentity: currentIdentity ?? undefined,
          } as StoredTokensData),
      );
    },
    [setStoredTokens],
  );

  // Returns a valid account access token, or throws if the currently stored
  // tokens are not account tokens. If the current access token is no longer
  // valid, both tokens will be renewed by calling the refresh endpoint.
  const getAccountAccessTokenOrThrow = useCallback(
    async (forceRefresh = false) => {
      const tokens = getLatestTokens();
      if (!tokens) {
        throw new AuthError(
          "Couldn't get a valid account access token: stored refresh token has the wrong type.",
          AuthErrorCode.UNEXPECTED_TOKENS_STORAGE_FAILURE,
        );
      }

      if (!forceRefresh && !isTokenExpired(tokens.accessToken)) {
        return tokens.accessToken;
      }

      if (!forceRefresh && isTokenExpired(tokens.refreshToken)) {
        throw new AuthError(
          "Couldn't get a valid account access token: stored refresh token is no longer valid.",
          AuthErrorCode.INVALID_REFRESH_TOKEN,
        );
      }

      try {
        return await refreshAccountTokensOrThrow(tokens);
      } catch (e) {
        throw new AuthError(
          "Couldn't get a valid account access token: error while refreshing tokens.",
          AuthErrorCode.ACCESS_TOKEN_REFRESH_FAILURE,
          e,
        );
      }
    },
    [getLatestTokens, refreshAccountTokensOrThrow],
  );

  const getLatestAccountTokensOrThrow = useCallback(() => {
    const tokens = getLatestTokens();
    if (!tokens) {
      throw new AuthError(
        "Couldn't get valid account tokens: stored tokens has the wrong type.",
        AuthErrorCode.UNEXPECTED_TOKENS_STORAGE_FAILURE,
      );
    }
    return {
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    };
  }, [getLatestTokens]);

  // ----- Public interface.

  const accountLoginWithPassword = useCallback(
    async ({
      email,
      password,
      mfaCode,
    }: AccountLoginWithPasswordPayload): Promise<LoginWithPasswordResult> => {
      try {
        const data = await loginWithPasswordMutation(
          { email, password, mfaCode },
          { requestContext: { regionByAccountEmail: email } },
        );

        switch (data.loginResponse.__typename) {
          case "LoginResponseMfaRequired":
            for (const method of data.loginResponse.mfaState.setupMethods) {
              switch (method.__typename) {
                case "TotpMfaMethod":
                  if (method.isSetup) {
                    return {
                      kind: "MFA_REQUIRED",
                      mfaByTotp: true,
                      mfaByPhone: undefined,
                    };
                  }
                  break;
                case "SmsMfaMethod":
                  if (method.isSetup && method.phone) {
                    return {
                      kind: "MFA_REQUIRED",
                      mfaByTotp: undefined,
                      mfaByPhone: {
                        accountEmail: email,
                        phoneNumber: method.phone,
                        antiAbuseToken: method.mfaBySmsAntiAbuseToken,
                      },
                    };
                  }
                  break;
              }
            }
            throw new Error("Unsupported MFA configuration");

          case "LoginResponseSuccess":
            if (
              data.loginResponse.jwtTokens.accessToken &&
              data.loginResponse.jwtTokens.refreshToken
            ) {
              setStoredTokens({
                accessToken: parseFreshAccountJwt(
                  data.loginResponse.jwtTokens.accessToken,
                ),
                refreshToken: parseFreshAccountJwt(
                  data.loginResponse.jwtTokens.refreshToken,
                ),
              });

              return { kind: "SUCCESS" };
            }
            throw new Error("No tokens received.");

          default:
            throw new Error("Unexpected login response");
        }
      } catch (e) {
        console.error(e);
        if (e instanceof ParsedGraphQLError) {
          switch (e.code) {
            case AppErrorCode.INVALID_LOGIN_CREDENTIALS:
            case AppErrorCode.PASSWORD_LOGIN_DISABLED:
            case AppErrorCode.TOO_MANY_WRONG_PASSWORDS:
              notifyError(
                e.display,
                new AuthError(
                  "Couldn't login with a password.",
                  AuthErrorCode.LOGIN_FAILURE,
                  e,
                ),
              );
              return { kind: "CREDENTIALS_FAILURE" };

            case AppErrorCode.INVALID_MFA_CODE:
            case AppErrorCode.TOO_MANY_WRONG_MFA_CODES:
              notifyError(
                e.display,
                new AuthError(
                  "Couldn't login with a password.",
                  AuthErrorCode.LOGIN_FAILURE,
                  e,
                ),
              );
              return { kind: "MFA_FAILURE" };
          }
        }
        notifyError(
          t("auth.login_failed"),
          new AuthError(
            "Couldn't login with a password.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
        return { kind: "OTHER_FAILURE" };
      }
    },
    [loginWithPasswordMutation, setStoredTokens],
  );

  // Logs in with raw account access and refresh tokens.
  // Used mainly to auto-login after a successful credentials reset.
  const accountLoginWithTokens = useCallback(
    (payload: AccountLoginWithTokensPayload) => {
      try {
        setStoredTokens({
          accessToken: parseFreshAccountJwt(payload.accountAccessToken),
          refreshToken: parseFreshAccountJwt(payload.accountRefreshToken),
        });
      } catch (e) {
        notifyError(
          t("auth.login_failed"),
          new AuthError(
            "Couldn't login with account tokens.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
      }
    },
    [setStoredTokens],
  );

  const startAccountLoginWithGoogle = useCallback(
    async ({
      emailLoginHint,
      redirectPath,
      requestCalendarEventsAccess,
    }: StartAccountLoginWithGooglePayload): Promise<StartAccountLoginWithGoogleResult> => {
      try {
        const { data } = await queryEndpoint(
          REST_ENDPOINTS.ACCOUNT_GOOGLE_AUTHENTICATION_URL,
          {
            params: {
              login_hint: emailLoginHint,
              redirect_path: redirectPath,
              request_calendar_events_access: requestCalendarEventsAccess,
            },
            requestContext: { region: "CLOSEST_REGION" },
          },
        );

        return { kind: "SUCCESS", authenticationUrl: data.authentication_url };
      } catch (e) {
        notifyError(
          t("auth.google_login_or_authorization_failed"),
          new AuthError(
            "Couldn't start Google sign-in.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
        return { kind: "FAILURE" };
      }
    },
    [],
  );

  const completeAccountLoginWithGoogle = useCallback(
    async ({
      state,
      code,
    }: {
      state: string;
      code: string;
    }): Promise<{ kind: "SUCCESS" | "FAILURE" }> => {
      try {
        // This needs to be a two-step process because the `code` we receive in
        // the payload from Google doesn't allow us to know the email address of
        // the account that the user picked in the Google account picker; so we
        // can't know which region to route the call to. Instead, we redeem the
        // code on the closest backend, and then we create the session on the
        // "correct" backend. See #26068.

        const { data: submitCodeData } = await queryEndpoint(
          REST_ENDPOINTS.ACCOUNT_GOOGLE_SUBMIT_OAUTH_CODE,
          {
            data: { state, code },
            requestContext: { region: "CLOSEST_REGION" },
          },
        );

        const { data: completeLoginData } = await queryEndpoint(
          REST_ENDPOINTS.ACCOUNT_GOOGLE_COMPLETE_LOGIN,
          {
            data: {
              google_session_data_handle:
                submitCodeData.google_session_data_handle,
            },
            requestContext: { regionByAccountEmail: submitCodeData.email },
          },
        );

        setStoredTokens({
          accessToken: parseFreshAccountJwt(completeLoginData.access_token),
          refreshToken: parseFreshAccountJwt(completeLoginData.refresh_token),
        });

        return { kind: "SUCCESS" };
      } catch (e) {
        notifyError(
          t("auth.google_login_or_authorization_failed"),
          new AuthError(
            "Couldn't complete Google sign-in.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
        return { kind: "FAILURE" };
      }
    },
    [setStoredTokens],
  );

  const completeGoogleAuthorization = useCallback(
    async ({
      state,
      code,
    }: {
      state: string;
      code: string;
    }): Promise<{ kind: "SUCCESS" | "FAILURE" }> => {
      try {
        if (storedTokenType !== "ACCOUNT") {
          throw new Error("User is not logged in with account tokens.");
        }

        await queryEndpoint(
          REST_ENDPOINTS.ACCOUNT_GOOGLE_COMPLETE_AUTHORIZATION,
          {
            data: { code, state },
            requestContext: {
              accountAccessToken: getAccountAccessTokenOrThrow,
            },
          },
        );

        return { kind: "SUCCESS" };
      } catch (e) {
        notifyError(
          t("auth.google_login_or_authorization_failed"),
          new AuthError(
            "Couldn't complete Google authorization.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
        return { kind: "FAILURE" };
      }
    },
    [storedTokenType, getAccountAccessTokenOrThrow],
  );

  const completeGoogleLoginOrAuthorization = useCallback(
    async ({
      code,
      state,
    }: CompleteGoogleLoginOrAuthorizationPayload): Promise<CompleteGoogleLoginOrAuthorizationResult> => {
      let parsedState: z.infer<typeof googleOAuthStateSchema>;
      try {
        if (!code) throw new Error("Missing `code` parameter.");
        if (!state) throw new Error("Missing `state` parameter.");
        parsedState = googleOAuthStateSchema.parse(JSON.parse(state));
      } catch (e) {
        notifyError(
          t("auth.google_login_or_authorization_failed"),
          new AuthError(
            "Couldn't complete Google login or authorization.",
            AuthErrorCode.LOGIN_FAILURE,
            e,
          ),
        );
        return { kind: "FAILURE" };
      }

      const { kind } = await run(() => {
        switch (parsedState.type) {
          case "AUTHENTICATION":
            return completeAccountLoginWithGoogle({ state, code });
          case "AUTHORIZATION":
            return completeGoogleAuthorization({ state, code });
        }
      });

      return { kind, redirectPath: parsedState.redirectPath ?? undefined };
    },
    [completeAccountLoginWithGoogle, completeGoogleAuthorization],
  );

  // Logs out and gracefully terminates the associated user session.
  const logout = useCallback(async () => {
    const tokens = getLatestTokens();
    if (!tokens) return;
    try {
      const { type, token } = tokens.refreshToken;
      switch (type) {
        case "ACCOUNT":
          await logoutMutation(
            {
              refreshJwtToken: token,
            },
            {
              requestContext: { region: jwtRegion(tokens.refreshToken) },
            },
          );
          break;
      }
    } catch (exception) {
      notifier.error({ sentry: { exception } });
    }

    setStoredTokens(null);
  }, [setStoredTokens, getLatestTokens, logoutMutation]);

  const authState: AuthState = useMemo(() => {
    switch (storedTokenType) {
      case undefined:
        return { state: "LOGGED_OUT" };

      case "ACCOUNT":
        const identityData =
          currentIdentityType === "COPILOT_API_DEVELOPER"
            ? {
                currentIdentity: {
                  type: "COPILOT_API_DEVELOPER" as const,
                  uuid: currentIdentityUuid!,
                },
                copilotApiUserRequestContext: {
                  accountAccessToken: getAccountAccessTokenOrThrow,
                  currentIdentityUuid: currentIdentityUuid!,
                },
              }
            : { currentIdentity: undefined };

        return {
          state: "LOGGED_IN",
          getLatestAccountTokens: getLatestAccountTokensOrThrow,
          logout,
          currentImpersonationUuid,
          setCurrentImpersonationUuid,
          currentSessionLoginMethod: currentSessionLoginMethod!,
          setCurrentIdentity,
          accountRequestContext: {
            accountAccessToken: getAccountAccessTokenOrThrow,
          },
          ...identityData,
        };
    }
  }, [
    storedTokenType,
    logout,
    currentImpersonationUuid,
    currentSessionLoginMethod,
    getLatestAccountTokensOrThrow,
    setCurrentIdentity,
    getAccountAccessTokenOrThrow,
    currentIdentityType,
    currentIdentityUuid,
  ]);

  // Important: the identity of the object returned by the context should be
  // stable across token refreshes, and should only change when the user logs
  // in or out or changes identity. To guarantee this, be careful about the
  // dependencies of the various methods.
  const contextValue: AuthContextType = useMemo(
    () => ({
      accountLoginWithPassword,
      accountLoginWithTokens,
      startAccountLoginWithGoogle,
      completeGoogleLoginOrAuthorization,
      ...authState,
    }),
    [
      accountLoginWithPassword,
      accountLoginWithTokens,
      startAccountLoginWithGoogle,
      completeGoogleLoginOrAuthorization,
      authState,
    ],
  );

  // ----- URL authentication.

  return <AuthContext.Provider value={contextValue} children={children} />;
};

const notifyError = (message: string, exception: AuthError) => {
  // #27240: The most likely scenario for an `ExpiredTokenError` is that the
  //  device's clock is significantly out of sync with the backend's clock.
  const user =
    exception.cause instanceof ExpiredTokenError
      ? `${message} ${t("auth.clock_drift_warning")}`
      : message;

  notifier.error({ user, sentry: { exception } });
};
